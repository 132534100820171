import Vue from 'vue'
import VueGtag from 'vue-gtag'

export default ({ isDev }) => {
  if (!isDev) {
    Vue.use(VueGtag, {
      config: {
        id: process.env.NUXT_ENV_GTAG_ID,
        params: {
          send_page_view: true,
        },
      },
    })
  }
}
