
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('navigations', ['footer']),

    contact() {
      return this.$store.state.globals.contact
    },
  },
}
