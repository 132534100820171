import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6a51d098"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navigation: require('/vercel/path0/components/general/navigation/Navigation.vue').default,Breaky: require('/vercel/path0/components/general/Breaky.vue').default,AppFooter: require('/vercel/path0/components/general/AppFooter.vue').default})
