import locale6c5af57f from '../../lang/de.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"de"},
  vueI18nLoader: false,
  locales: [{"code":"de","name":"Deutsch","iso":"de","file":"de.json","isCatchallLocale":true},{"code":"fr","name":"Français","iso":"fr","file":"fr.json"},{"code":"it","name":"Italiano","iso":"it","file":"it.json"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"de","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"de","name":"Deutsch","iso":"de","file":"de.json","isCatchallLocale":true},{"code":"fr","name":"Français","iso":"fr","file":"fr.json"},{"code":"it","name":"Italiano","iso":"it","file":"it.json"}],
  localeCodes: ["de","fr","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: ".",
  5: "j",
  6: "s",
  7: "o",
  8: "n",
  9: "\"",
  10: ":",
  11: "\"",
  12: ".",
  13: ".",
  14: "/",
  15: "l",
  16: "a",
  17: "n",
  18: "g",
  19: "/",
  20: "d",
  21: "e",
  22: ".",
  23: "j",
  24: "s",
  25: "o",
  26: "n",
  27: "\"",
  28: ",",
  29: "\"",
  30: "f",
  31: "r",
  32: ".",
  33: "j",
  34: "s",
  35: "o",
  36: "n",
  37: "\"",
  38: ":",
  39: "\"",
  40: ".",
  41: ".",
  42: "/",
  43: "l",
  44: "a",
  45: "n",
  46: "g",
  47: "/",
  48: "f",
  49: "r",
  50: ".",
  51: "j",
  52: "s",
  53: "o",
  54: "n",
  55: "\"",
  56: ",",
  57: "\"",
  58: "i",
  59: "t",
  60: ".",
  61: "j",
  62: "s",
  63: "o",
  64: "n",
  65: "\"",
  66: ":",
  67: "\"",
  68: ".",
  69: ".",
  70: "/",
  71: "l",
  72: "a",
  73: "n",
  74: "g",
  75: "/",
  76: "i",
  77: "t",
  78: ".",
  79: "j",
  80: "s",
  81: "o",
  82: "n",
  83: "\"",
  84: "}",
}

export const localeMessages = {
  'de.json': () => Promise.resolve(locale6c5af57f),
  'fr.json': () => import('../../lang/fr.json' /* webpackChunkName: "lang-fr.json" */),
  'it.json': () => import('../../lang/it.json' /* webpackChunkName: "lang-it.json" */),
}
