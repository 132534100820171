export const state = () => ({
  pageHasAlternates: true,
})

export const mutations = {
  setPageHasAlternates(state, hasAlternates) {
    state.pageHasAlternates = hasAlternates
  },
}

export const actions = {
  checkIfhasAlternates({ commit }, alternates) {
    commit('setPageHasAlternates', alternates.length > 1)
  },
}
