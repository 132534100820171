
export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.scrollTo(0, 0)
    })
  },
}
