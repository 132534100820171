export default ({ app }, inject) => {
  inject('convertToURL', (url) => {
    if (!url) return null

    if (!/^https?:\/\//i.test(url)) {
      return (url = 'http://' + url)
    }

    return url
  })
}
