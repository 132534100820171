export default function ({ $axios, error: nuxtError, i18n }) {
  $axios.onRequest((config) => {
    config.headers.common['accept-language'] = i18n.locale
  })

  // https://axios.nuxtjs.org/helpers/#interceptors
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    if (code === 503) {
      nuxtError({ statusCode: 503 })
      return Promise.resolve(false)
    }

    if (code === 403 && process.server) {
      nuxtError({ statusCode: 403 })
      return Promise.resolve(false)
    }
  })
}
