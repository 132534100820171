import { render, staticRenderFns } from "./LangSwitcher.vue?vue&type=template&id=7a19a7f8"
import script from "./LangSwitcher.vue?vue&type=script&lang=js"
export * from "./LangSwitcher.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChevronDown: require('/vercel/path0/components/icons/ChevronDown.vue').default})
