
export default {
  props: {
    cta: {
      type: Object,
      default: null,
    },

    navItems: {
      type: Array,
      default: () => [],
    },

    navItemsRight: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      tl: null,
    }
  },

  async mounted() {
    await this.$nextTick()

    this.$gsap.delayedCall(0.4, this.initAnimation)
  },

  methods: {
    initAnimation() {
      this.tl = this.$gsap.timeline().to('.nav-link.list-title', {
        y: 0,
        opacity: 1,
        duration: 1,
        ease: 'power4',
        stagger: 0.11,
      })
    },
  },
}
