
import throttle from 'lodash/throttle'

export default {
  props: {
    thresholdCrossed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  watch: {
    isOpen(isOpen) {
      if (isOpen) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    },

    '$route.path'() {
      this.close()
    },
  },

  mounted() {
    this.registerResizeEvent()
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        return this.$emit('opened')
      }

      this.$emit('closed')
    },

    close() {
      this.isOpen = false
      this.$emit('closed')
    },

    registerResizeEvent() {
      this.$nextTick(() => {
        window.addEventListener(
          'resize',
          throttle(() => {
            if (window.innerWidth >= 1024) {
              this.close()
            }
          }, 500)
        )
      })
    },
  },
}
