
export default {
  data() {
    return {
      isDev: process.env.NODE_ENV === 'development',
    }
  },

  mounted() {
    this.detectIfAppIsIniFrame()

    window.addEventListener('keydown', this.navigateToSearchPage)
  },

  destroyed() {
    window.removeEventListener('keydown', this.navigateToSearchPage)
  },

  methods: {
    detectIfAppIsIniFrame() {
      if (window.location !== window.parent.location) {
        return document.body.classList.add('is-within-iframe')
      }
    },

    navigateToSearchPage(e) {
      if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
        return this.$router.push(this.localePath('/search'))
      }

      if (e.key === 'Escape') {
        this.$router.back()
      }
    },
  },
}
