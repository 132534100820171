
import throttle from 'lodash/throttle'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      mobileMenuIsOpen: false,
      thresholdCrossed: false,
    }
  },

  computed: {
    ...mapState('navigations', ['main']),

    navItems() {
      return this.main?.tree?.filter(({ page }) => {
        return !page.is_cta_button && !page.is_right && !page.is_hidden
      })
    },

    navItemsRight() {
      return this.main?.tree?.filter(({ page }) => {
        return page.is_right && !page.is_hidden
      })
    },

    cta() {
      return this.main?.tree.filter(({ page }) => page.is_cta_button)[0]
    },
  },

  mounted() {
    this.registerScrollEvent()
  },

  methods: {
    registerScrollEvent() {
      this.$nextTick(() => {
        window.addEventListener('scroll', throttle(this.handleScroll, 200))
      })
    },

    handleScroll() {
      const scrollTop = window.pageYOffset
      const navHeight = this.$el.clientHeight
      const thresholdCrossed = scrollTop > navHeight
      this.thresholdCrossed = thresholdCrossed
    },
  },
}
