import Vue from 'vue'

Vue.directive('content', {
  inserted: (el, { value }) => {
    el.innerHTML = value.data

    setTimeout(() => {
      const isInIFrame = document.body.classList.contains('is-within-iframe')

      if (isInIFrame && !value.data) {
        el.classList.add('cms-placeholder')
        return (el.innerHTML = value.label)
      }

      if (!isInIFrame && !value.data) {
        return el.remove()
      }
    }, 300)
  },

  update: (el, { value }) => {
    if (value.data) {
      el.classList.remove('cms-placeholder')
      return (el.innerHTML = value.data)
    }

    const isInIFrame = document.body.classList.contains('is-within-iframe')

    if (isInIFrame && !value.data) {
      el.classList.add('cms-placeholder')
      el.innerHTML = value.label
    }
  },
})
