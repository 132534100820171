import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_1a84675c from 'nuxt_plugin_plugin_1a84675c' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_485e2b94 from 'nuxt_plugin_sentryserver_485e2b94' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_761778ae from 'nuxt_plugin_sentryclient_761778ae' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_axios_db3c871c from 'nuxt_plugin_axios_db3c871c' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_3a8ae409 from 'nuxt_plugin_pluginutils_3a8ae409' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_79629314 from 'nuxt_plugin_pluginrouting_79629314' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_29c150db from 'nuxt_plugin_pluginmain_29c150db' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtcloudinaryimageplugin640425d7_f5e72c46 from 'nuxt_plugin_nuxtcloudinaryimageplugin640425d7_f5e72c46' // Source: ./nuxt-cloudinary-image.plugin.640425d7.js (mode: 'all')
import nuxt_plugin_datefns_be925fcc from 'nuxt_plugin_datefns_be925fcc' // Source: ./date-fns.js (mode: 'all')
import nuxt_plugin_gsapModule_3bc2c81f from 'nuxt_plugin_gsapModule_3bc2c81f' // Source: ./gsapModule.js (mode: 'all')
import nuxt_plugin_graphqlrequest_25cde6dd from 'nuxt_plugin_graphqlrequest_25cde6dd' // Source: ./graphql-request.js (mode: 'all')
import nuxt_plugin_livepreview_10b24b0c from 'nuxt_plugin_livepreview_10b24b0c' // Source: ../plugins/live-preview.js (mode: 'all')
import nuxt_plugin_livepreviewdetector_78fdcdfe from 'nuxt_plugin_livepreviewdetector_78fdcdfe' // Source: ../plugins/live-preview-detector.js (mode: 'all')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'all')
import nuxt_plugin_vuecloudinaryimagestatamic_a1cacfee from 'nuxt_plugin_vuecloudinaryimagestatamic_a1cacfee' // Source: ../plugins/vue-cloudinary-image-statamic.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_veevalidate_1a0c1998 from 'nuxt_plugin_veevalidate_1a0c1998' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_vue2googlemaps_51da65b7 from 'nuxt_plugin_vue2googlemaps_51da65b7' // Source: ../plugins/vue2-google-maps.js (mode: 'all')
import nuxt_plugin_vueyoutube_6da200de from 'nuxt_plugin_vueyoutube_6da200de' // Source: ../plugins/vue-youtube.js (mode: 'all')
import nuxt_plugin_removeptags_31964c4e from 'nuxt_plugin_removeptags_31964c4e' // Source: ../plugins/remove-p-tags.js (mode: 'all')
import nuxt_plugin_removehtmltags_07a05330 from 'nuxt_plugin_removehtmltags_07a05330' // Source: ../plugins/remove-html-tags (mode: 'all')
import nuxt_plugin_ishtml_6025fa38 from 'nuxt_plugin_ishtml_6025fa38' // Source: ../plugins/is-html (mode: 'all')
import nuxt_plugin_vueclickoutsideclient_5fb36771 from 'nuxt_plugin_vueclickoutsideclient_5fb36771' // Source: ../plugins/vue-click-outside.client.js (mode: 'client')
import nuxt_plugin_vueawesomeswiper_5ce03f58 from 'nuxt_plugin_vueawesomeswiper_5ce03f58' // Source: ../plugins/vue-awesome-swiper.js (mode: 'all')
import nuxt_plugin_vueselect_af0f7784 from 'nuxt_plugin_vueselect_af0f7784' // Source: ../plugins/vue-select.js (mode: 'all')
import nuxt_plugin_vuedatepickerui_223c856f from 'nuxt_plugin_vuedatepickerui_223c856f' // Source: ../plugins/vue-datepicker-ui.js (mode: 'all')
import nuxt_plugin_gsap_0d8e3474 from 'nuxt_plugin_gsap_0d8e3474' // Source: ../plugins/gsap.js (mode: 'all')
import nuxt_plugin_googleanalytics_02623c11 from 'nuxt_plugin_googleanalytics_02623c11' // Source: ../plugins/google-analytics.client (mode: 'client')
import nuxt_plugin_vueinstantsearch_9a9113e8 from 'nuxt_plugin_vueinstantsearch_9a9113e8' // Source: ../plugins/vue-instantsearch (mode: 'all')
import nuxt_plugin_converttourl_89491526 from 'nuxt_plugin_converttourl_89491526' // Source: ../plugins/convert-to-url (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"fade","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"STV - FST","titleTemplate":"%s","htmlAttrs":{"lang":"de"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"preconnect","href":"https:\u002F\u002Ffonts.gstatic.com\u002F","crossorigin":""},{"rel":"stylesheet","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"},{"rel":"mask-icon","href":"\u002Fsafari-pinned-tab.svg","color":"#CC0039"},{"rel":"manifest","href":"\u002Fsite.webmanifest"},{"rel":"apple-touch-icon","sizes":"180x180","href":"\u002Fapple-touch-icon.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"32x32","href":"\u002Ffavicon-32x32.png"},{"rel":"icon","type":"image\u002Fpng","sizes":"16x16","href":"\u002Ffavicon-16x16.png"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_1a84675c === 'function') {
    await nuxt_plugin_plugin_1a84675c(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_485e2b94 === 'function') {
    await nuxt_plugin_sentryserver_485e2b94(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_761778ae === 'function') {
    await nuxt_plugin_sentryclient_761778ae(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_db3c871c === 'function') {
    await nuxt_plugin_axios_db3c871c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_3a8ae409 === 'function') {
    await nuxt_plugin_pluginutils_3a8ae409(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_79629314 === 'function') {
    await nuxt_plugin_pluginrouting_79629314(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_29c150db === 'function') {
    await nuxt_plugin_pluginmain_29c150db(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtcloudinaryimageplugin640425d7_f5e72c46 === 'function') {
    await nuxt_plugin_nuxtcloudinaryimageplugin640425d7_f5e72c46(app.context, inject)
  }

  if (typeof nuxt_plugin_datefns_be925fcc === 'function') {
    await nuxt_plugin_datefns_be925fcc(app.context, inject)
  }

  if (typeof nuxt_plugin_gsapModule_3bc2c81f === 'function') {
    await nuxt_plugin_gsapModule_3bc2c81f(app.context, inject)
  }

  if (typeof nuxt_plugin_graphqlrequest_25cde6dd === 'function') {
    await nuxt_plugin_graphqlrequest_25cde6dd(app.context, inject)
  }

  if (typeof nuxt_plugin_livepreview_10b24b0c === 'function') {
    await nuxt_plugin_livepreview_10b24b0c(app.context, inject)
  }

  if (typeof nuxt_plugin_livepreviewdetector_78fdcdfe === 'function') {
    await nuxt_plugin_livepreviewdetector_78fdcdfe(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  if (typeof nuxt_plugin_vuecloudinaryimagestatamic_a1cacfee === 'function') {
    await nuxt_plugin_vuecloudinaryimagestatamic_a1cacfee(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c1998 === 'function') {
    await nuxt_plugin_veevalidate_1a0c1998(app.context, inject)
  }

  if (typeof nuxt_plugin_vue2googlemaps_51da65b7 === 'function') {
    await nuxt_plugin_vue2googlemaps_51da65b7(app.context, inject)
  }

  if (typeof nuxt_plugin_vueyoutube_6da200de === 'function') {
    await nuxt_plugin_vueyoutube_6da200de(app.context, inject)
  }

  if (typeof nuxt_plugin_removeptags_31964c4e === 'function') {
    await nuxt_plugin_removeptags_31964c4e(app.context, inject)
  }

  if (typeof nuxt_plugin_removehtmltags_07a05330 === 'function') {
    await nuxt_plugin_removehtmltags_07a05330(app.context, inject)
  }

  if (typeof nuxt_plugin_ishtml_6025fa38 === 'function') {
    await nuxt_plugin_ishtml_6025fa38(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueclickoutsideclient_5fb36771 === 'function') {
    await nuxt_plugin_vueclickoutsideclient_5fb36771(app.context, inject)
  }

  if (typeof nuxt_plugin_vueawesomeswiper_5ce03f58 === 'function') {
    await nuxt_plugin_vueawesomeswiper_5ce03f58(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_af0f7784 === 'function') {
    await nuxt_plugin_vueselect_af0f7784(app.context, inject)
  }

  if (typeof nuxt_plugin_vuedatepickerui_223c856f === 'function') {
    await nuxt_plugin_vuedatepickerui_223c856f(app.context, inject)
  }

  if (typeof nuxt_plugin_gsap_0d8e3474 === 'function') {
    await nuxt_plugin_gsap_0d8e3474(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_02623c11 === 'function') {
    await nuxt_plugin_googleanalytics_02623c11(app.context, inject)
  }

  if (typeof nuxt_plugin_vueinstantsearch_9a9113e8 === 'function') {
    await nuxt_plugin_vueinstantsearch_9a9113e8(app.context, inject)
  }

  if (typeof nuxt_plugin_converttourl_89491526 === 'function') {
    await nuxt_plugin_converttourl_89491526(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
