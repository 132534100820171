import Vue from 'vue'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from 'vee-validate'
import {
  required,
  email,
  numeric,
  max,
  size,
  ext,
} from 'vee-validate/dist/rules'
import de from 'vee-validate/dist/locale/de.json'
import it from 'vee-validate/dist/locale/it.json'
import fr from 'vee-validate/dist/locale/fr.json'

extend('required', {
  ...required,
})
extend('email', {
  ...email,
})
extend('numeric', {
  ...numeric,
})
extend('integer', {
  ...numeric,
})
extend('max', {
  ...max,
})
extend('Max', {
  ...max,
})
extend('size', {
  ...size,
})
extend('ext', {
  ...ext,
})
extend('sometimes', () => {
  return true
})
extend('phone', (value, args) => {
  if (args.includes('CH')) {
    return /(\b(0041|0)|\B\+41)(\s?\(0\))?(\s)?[1-9]{2}(\s)?[0-9]{3}(\s)?[0-9]{2}(\s)?[0-9]{2}\b/.test(
      value.replace(/\s/g, '')
    )
  }

  // https://regex101.com/r/ooKq2J/1
  return /^(\+|0|00)([0-9]){9,}$/.test(value.replace(/\s/g, ''))
})
extend('url', (value) => {
  return /^https?:\/\//.test(value)
})

const dictionary = {
  de: {
    messages: {
      phone: () => 'Telefon muss eine gültige Telefonnummer sein',
    },
  },
  it: {
    messages: {
      phone: () => 'Il telefono deve essere un numero di telefono valido',
    },
  },
  fr: {
    messages: {
      phone: () => 'Le téléphone doit être un numéro de téléphone valide',
    },
  },
}

localize({
  de,
  it,
  fr,
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

export default function ({ app }) {
  localize(dictionary)
  localize(app.i18n.locale)

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    localize(newLocale)
  }
}
