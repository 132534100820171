
export default {
  props: {
    to: {
      type: [String, Object],
      required: true,
    },

    blank: {
      type: Boolean,
      default: null,
    },

    absolute: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    isExternalUrl() {
      return (
        this.absolute ||
        (typeof this.to === 'string' &&
          this.to.match(
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
          ))
      )
    },

    isValid() {
      return !!this.to
    },

    target() {
      if (this.blank === null) {
        return this.isExternalUrl
          ? '_blank'
          : null /* Target blank for external links */
      }
      return this.blank
        ? '_blank'
        : null /* Target by default null for relative links */
    },
  },
}
