
export default {
  props: {
    to: {
      type: String,
      default: null,
    },

    themeColorClasses: {
      type: String,
      default: null,
    },

    size: {
      // small, medium, large
      type: String,
      default: 'button-small',
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
