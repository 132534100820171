import { getNavigations } from '~/graphql/globals'

export const state = () => ({
  excludedUrls: [
    /^\/nachhaltigkeit\/kompetenzzentrum\/sustainable-tourism-marketplace/,
    /^\/fr\/durabilite\/centre-de-competences\/sustainable-tourism-marketplace/,
    /^\/it\/sostenibilita\/centro-di-competenza\/sustainable-tourism-marketplace/,
    /\/suma-angebot-ausschreiben/,
    /\/publier-une-offre-suma/,
    /\/offerta-suma/,
  ],
  main: null,
  unfilteredMain: null,
  footer: null,
  unfilteredFooter: null,
})

function filterTree(tree, excludedUrls) {
  return tree.reduce((tree, item) => {
    if (
      item.page?.url &&
      excludedUrls.some((excludedUrl) =>
        excludedUrl instanceof RegExp
          ? excludedUrl.test(item.page.url)
          : excludedUrl === item.page.url
      )
    ) {
      return tree
    }

    if (Array.isArray(item.children)) {
      item.children = filterTree(item.children, excludedUrls)
    }

    return [...tree, item]
  }, [])
}

export const mutations = {
  setNavigations(state, navigations) {
    // set Main Navigation
    state.main = navigations.navs.filter((nav) => nav.handle === 'main')[0]
    state.unfilteredMain = JSON.parse(
      JSON.stringify(navigations.navs.filter((nav) => nav.handle === 'main')[0])
    )

    // set Footer
    state.footer = navigations.navs.filter((nav) => nav.handle === 'footer')[0]
    state.unfilteredFooter = JSON.parse(
      JSON.stringify(
        navigations.navs.filter((nav) => nav.handle === 'footer')[0]
      )
    )

    // TODO: STV-212 temporarily remove items while client is testing the pages
    state.main.tree = filterTree(state.main.tree, state.excludedUrls)
    state.footer.tree = filterTree(state.footer.tree, state.excludedUrls)
  },
}

export const actions = {
  async getAllNavs({ commit }) {
    try {
      const navigations = await this.$graphql.cms.request(getNavigations, {
        lang: this.$i18n.locale,
      })

      commit('setNavigations', navigations)
    } catch (e) {
      console.log('navigations/getAllNavs', e)
    }
  },
}

export const getters = {}
